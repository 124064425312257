import { useEffect, useState } from "react";
import styled from "styled-components";
import Markdown from "react-markdown";

import { useLanguage } from "@clairejs/react";

const Wrapper = styled.div`
    padding-top: 54px;
    display: flex;
    align-items: center;
`;

interface Props {
    translations: Record<string, any>;
}
export default function MdPage(props: Props) {
    const [lang] = useLanguage();
    const [content, setContent] = useState("");

    useEffect(() => {
        if (lang)
            fetch(props.translations[lang])
                .then((res) => res.text())
                .then(setContent);
    }, [lang, props.translations]);

    return (
        <Wrapper>
            <div className="container">
                {lang && <Markdown>{content}</Markdown>}
            </div>
        </Wrapper>
    );
}
