import { ClaireApp, I18n } from "@clairejs/react";
import { LocalStorage, ReactWebRoutes } from "@clairejs/react-web";
import {
    AbstractLogger,
    AbstractProvider,
    ConsoleLogMedium,
    DefaultLogger,
    LogLevel,
    Provider,
    getServiceProvider,
    setSystemLocale,
} from "@clairejs/core";
import { RouterConfig, Translator } from "@clairejs/client";
import { createRoot } from "react-dom/client";

import "bootstrap/dist/css/bootstrap.min.css";
import "./index.scss";

import vi from "./assets/translations/vi.json";
import en from "./assets/translations/en.json";

import App from "./pages/app";
import Main from "./pages/main";
import NotFound from "./pages/not-found";
import Privacy from "./pages/privacy";
import Terms from "./pages/terms";
import Help from "./pages/help";

@Provider(AbstractLogger)
export class LoggerProvider extends AbstractProvider<AbstractLogger> {
    provide(): AbstractLogger {
        return new DefaultLogger(LogLevel.DEBUG, [new ConsoleLogMedium()]);
    }
}

const bootstrap = async () => {
    const serviceProvider = getServiceProvider();
    serviceProvider.register(LocalStorage);
    serviceProvider.register(Translator);

    setSystemLocale("en");

    //-- init translation
    const injector = serviceProvider.getInjector();
    await injector.initInstances();
};

const routes: RouterConfig = {
    path: "",
    component: { view: App },
    children: [
        { path: "", component: { view: Main } },
        { path: "privacy", component: { view: Privacy } },
        { path: "terms", component: { view: Terms } },
        { path: "help", component: { view: Help } },
        { path: "*", component: { view: NotFound } },
    ],
};

const container = document.getElementById("root")!;

createRoot(container).render(
    <ClaireApp bootstrap={bootstrap} stores={[]}>
        <I18n
            translations={{
                vi: Promise.resolve<any>(vi),
                en: Promise.resolve<any>(en),
            }}
        >
            <ReactWebRoutes routeConfig={routes} />
        </I18n>
    </ClaireApp>
);
