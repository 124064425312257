import styled from "styled-components";
import Dropdown from "react-bootstrap/Dropdown";
import { useEffect } from "react";

import { getServiceProvider, getSystemLocale } from "@clairejs/core";
import { useLanguage } from "@clairejs/react";
import { AbstractStorage } from "@clairejs/client";

import { IonIcon } from "@ionic/react";
import { language } from "ionicons/icons";
import { useNavigator } from "@clairejs/react-web";

const Wrapper = styled.div`
    min-width: 144px;
    display: flex;
    justify-content: flex-end;
`;

const langs: Record<string, { short: string; long: string }> = {
    vi: { short: "VI", long: "Tiếng Việt" },
    en: { short: "EN", long: "English" },
} as const;

const getLocalStorage = () =>
    getServiceProvider().getInjector().resolve(AbstractStorage);

const getPersistedLang = async () => {
    const storage = getLocalStorage();
    return await storage.getItem<string>("LANGUAGE");
};

const setPersistedLang = async (code: string) => {
    const storage = getLocalStorage();
    await storage.setItem("LANGUAGE", code);
};

export default function LanguageSwitcher() {
    const navigator = useNavigator();
    const [lang, setLang] = useLanguage();

    const setLanguage = async (code: string) => {
        setLang(code);
        await setPersistedLang(code);
    };

    useEffect(() => {
        if (!lang) {
            const queryLang = navigator.getQueries().lang;

            getPersistedLang().then((persistedLang) => {
                const userLang = queryLang || persistedLang;

                setLanguage(
                    userLang && langs[userLang] ? userLang : getSystemLocale()
                );
            });
        }
    }, [lang]);

    return (
        <Wrapper>
            {lang && (
                <Dropdown>
                    <Dropdown.Toggle
                        variant="outline"
                        style={{ display: "flex", alignItems: "center" }}
                    >
                        <div
                            style={{
                                display: "inline-flex",
                                alignItems: "center",
                                gap: 8,
                            }}
                        >
                            <IonIcon icon={language}></IonIcon>
                            <span
                                style={{
                                    fontFamily: "monospace",
                                    fontSize: 14,
                                }}
                            >
                                {langs[lang].short}
                            </span>
                        </div>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                        {Object.entries(langs).map(([code, text]) => (
                            <Dropdown.Item
                                key={code}
                                onClick={() => setLanguage(code)}
                                href="#"
                            >
                                {text.long}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </Wrapper>
    );
}
