import { Tlt } from "@clairejs/react";
import { Link, useNavigator } from "@clairejs/react-web";
import styled from "styled-components";

import { MutedTextColor, PrimaryColor, TextColor } from "../style";

const Wrapper = styled.div`
    .navbar {
        display: flex;
        gap: 8px;
        flex: 1;
    }

    .link {
        text-decoration: none;
        padding: 13px;
        color: ${MutedTextColor};
        transition-duration: 200ms;

        &:hover {
            color: ${TextColor};
        }

        &.active {
            color: ${PrimaryColor};
        }

        .link-icon {
            display: none;
        }
    }

    @media screen and (max-width: 1024px) {
        .navbar {
            display: none;
        }
    }
`;

export const links = [
    ["/", <Tlt template="Home" />],
    ["/terms", <Tlt template="Terms of use" />],
    ["/privacy", <Tlt template="Privacy policy" />],
    ["/help", <Tlt template="Help center" />],
] as const;

export default function PageNav() {
    const navigator = useNavigator();

    const isActive = (path: string) => {
        const currentPath = navigator.getPath();

        return (
            path === currentPath ||
            (path !== "/" && currentPath.startsWith(path))
        );
    };

    const getLink = (path: string, tlt: any) => {
        const active = isActive(path);

        return (
            <Link
                key={path}
                className={`link ${active ? "active" : ""}`}
                to={path}
            >
                <span className="link-text">{tlt}</span>
            </Link>
        );
    };

    const linkItems = links.map(([path, tlt]) => getLink(path, tlt));

    return (
        <Wrapper>
            <div className="navbar">{linkItems}</div>
        </Wrapper>
    );
}
