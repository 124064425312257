import { CSSProperties, useLayoutEffect, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 100%;

    .glass {
        /* From https://css.glass */
        background: rgba(255, 255, 255, 0);
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
    }
`;

const sticky: CSSProperties = {
    position: "fixed",
    top: 0,
    flex: 1,
    zIndex: 100,
    width: "100%",
};

const content = (height: number): CSSProperties => ({
    paddingTop: height,
});

interface Props {
    id: string;
    render?: any;
    children?: any;
    height: number;
    floatStyle?: CSSProperties;
}
export default function StickyHeader(props: Props) {
    const [float, setFloat] = useState(false);

    useLayoutEffect(() => {
        const header = document.getElementById(props.id);

        if (!header) return;

        const sticky = header.offsetTop;

        const check = () => {
            setFloat(window.scrollY > sticky);
        };

        window.addEventListener("scroll", check);

        return () => {
            window.removeEventListener("scroll", check);
        };
    }, [props.id]);

    return (
        <Wrapper>
            <div
                id={props.id}
                style={{
                    height: props.height,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    ...(float && {
                        ...props.floatStyle,
                        ...sticky,
                    }),
                }}
                className={float ? "glass" : ""}
            >
                {props.render}
            </div>
            <div
                style={{
                    ...(float && content(props.height)),
                }}
            ></div>
        </Wrapper>
    );
}
