import styled from "styled-components";

const Wrapper = styled.div`
    display: inline-block;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
`;

interface Props {
    img: any;
    link: string;
    className?: string;
}
export default function ImageButton(props: Props) {
    return (
        <Wrapper className={props.className}>
            <a href={props.link}>
                <img alt="button" src={props.img} />
            </a>
        </Wrapper>
    );
}
