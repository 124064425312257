import { Link, Outlet } from "@clairejs/react-web";
import styled from "styled-components";
import { getText, useLanguage } from "@clairejs/react";

import PageNav, { links } from "../components/page-nav";
import LanguageSwitcher from "../components/language-switcher";

import freeTextLogo from "../assets/images/free.svg";
import StickyHeader from "../components/sticky-header";
import { LightPrimaryColor } from "../style";

const Wrapper = styled.div`
    flex: 1;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .max-1024 {
        width: 100%;
        max-width: 1024px;
        padding: 0px 55px;
    }

    .head {
        flex: 1;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            height: 34px;
            object-fit: contain;
        }

        .nav {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .menu {
                display: flex;
                align-items: center;
            }
            .lang-switcher {
            }
        }
    }

    .main-content {
        flex: 1;
    }

    .footer {
        width: 100%;
        display: none;
        margin-top: 89px;
        padding: 34px 0;

        .links {
            gap: 8px;
        }

        .bottom-link {
            color: #ffffff;
            text-decoration: none;
        }
    }

    @media screen and (max-width: 1024px) {
        .footer {
            display: flex;
            background-color: ${LightPrimaryColor};
            color: #ffffff;
        }
    }

    @media screen and (max-width: 480px) {
        .max-1024 {
            padding: 0px 34px;
        }

        .footer {
            margin-top: 13px;
            padding: 21px 0;
        }
    }
`;

function App() {
    useLanguage();

    return (
        <Wrapper>
            <StickyHeader
                id="sticky-header"
                height={89}
                floatStyle={{
                    backgroundColor: "rgba(255,255,255,0.9)",
                }}
                render={
                    <div className="head max-1024">
                        <img className="logo" alt="logo" src={freeTextLogo} />
                        <div className="nav">
                            <div className="menu">
                                <PageNav />
                            </div>
                            <div className="lang-switcher">
                                <LanguageSwitcher />
                            </div>
                        </div>
                    </div>
                }
            />
            <div className="max-1024 main-content">
                <Outlet></Outlet>
            </div>
            <div className="footer">
                <div className="max-1024" style={{ width: "100%" }}>
                    <div
                        className="links"
                        style={{ display: "flex", flexDirection: "column" }}
                    >
                        <Link className="bottom-link" to={links[0][0]}>
                            <span>{getText(links[0][1])}</span>
                        </Link>
                        <Link className="bottom-link" to={links[1][0]}>
                            <span>{getText(links[1][1])}</span>
                        </Link>
                        <Link className="bottom-link" to={links[2][0]}>
                            <span>{getText(links[2][1])}</span>
                        </Link>
                        <Link className="bottom-link" to={links[3][0]}>
                            <span>{getText(links[3][1])}</span>
                        </Link>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default App;
