import styled from "styled-components";
import { Tlt, getText, useLanguage } from "@clairejs/react";

import imgIntro from "../assets/images/showcase.png";
import appStore from "../assets/images/app-store.svg";
import googlePlay from "../assets/images/play-store.svg";

import ImageButton from "../components/image-button";
import { MutedTextColor, PrimaryColor } from "../style";

const Wrapper = styled.div`
    justify-content: space-between;
    display: flex;
    margin: auto;
    padding-top: 89px;

    .primary {
        color: ${PrimaryColor};
    }

    .left {
    }

    .right {
        /* border: 1px solid red; */
        flex: 1;
        display: flex;
        justify-content: flex-end;

        img {
            height: 500px;
        }
    }

    .store-button {
        width: 160px;
    }

    .intro {
        font-size: 34px;
    }

    .app-intro {
        color: ${MutedTextColor};
        padding-top: 21px;
        padding-bottom: 21px;
        white-space: pre-wrap;
    }

    .download {
        margin-top: 21px;
        display: flex;
        align-items: center;
        gap: 34px;
    }

    .hover {
        transition-duration: 300ms;
        &:hover {
            transform: scale(1.02);
        }
    }

    @media screen and (max-width: 1024px) {
        .intro {
            font-size: 24px;
        }

        .app-intro {
            font-size: 14px;
        }

        .download {
            margin-top: 8px;
            gap: 21px;
        }

        .store-button {
            width: 120px;
        }

        .left {
            margin-bottom: 55px;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .right {
            img {
                height: 400px;
            }
        }
    }

    @media screen and (max-width: 768px) {
        flex-direction: column-reverse;
        padding-top: 34px;

        .intro {
            font-size: 24px;
        }

        .left {
            margin-top: 34px;
        }

        .right {
            /* border: 1px solid red; */
            padding: 8px;
            justify-content: center;
            height: 180px;
            flex: unset;
            overflow: hidden;
            mask-image: linear-gradient(to bottom, black 80%, transparent 100%);

            img {
                /* border: 1px solid red; */
            }
        }
    }

    @media screen and (max-width: 480px) {
        padding-top: 5px;

        .intro {
            font-size: 22px;
        }

        .app-intro {
            text-align: center;
        }

        .left {
            margin-top: 34px;
        }

        .right {
            height: 120px;
            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .download {
            display: flex;
            flex-direction: column;
            align-items: center;
            .store-button {
                width: 200px;
            }
        }
    }
`;

const IOS_STORE_LINK = "https://apps.apple.com/us/app/Free/id6479576443";
const ANDROID_STORE_LINK =
    "https://play.google.com/store/apps/details?id=com.tamidi.alovendor";

export default function Main() {
    const [lang] = useLanguage();

    return (
        <Wrapper>
            <div className="left">
                <div className="intro bold">
                    <Tlt template="Find services" />
                </div>
                <div className="intro bold">
                    <Tlt template="Become vendors" />
                </div>
                <div
                    className="intro bold"
                    dangerouslySetInnerHTML={{
                        __html: getText(
                            <Tlt
                                template="Within a single {free} app"
                                data={{
                                    free: `<span class="primary">Free</span>`,
                                }}
                            />
                        ),
                    }}
                ></div>
                <div className="app-intro" style={{}}>
                    <Tlt id="app_intro" />
                </div>
                <div className="download">
                    <ImageButton
                        className="store-button hover"
                        img={appStore}
                        link={IOS_STORE_LINK}
                    />
                    <ImageButton
                        className="store-button hover"
                        img={googlePlay}
                        link={ANDROID_STORE_LINK}
                    />
                </div>
            </div>
            <div className="right">
                <img className="hover" alt="intro" src={imgIntro} />
            </div>
        </Wrapper>
    );
}
